import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-multiline">
              <div className="column is-10 is-offset-1">
                <div className="content">
                    <h2 className="title">{mainpitch.title}</h2>
                  <div className="is-size-4">
                    {/* <h3 className="subtitle">{mainpitch.description}</h3> */}
                    <p>In 2022 I read a book by Abraham Joshua Heschel called <em>"Quest for G-d"</em> that has ruined me in all of the best ways. A Jewish rabbi and theologian, his poetic prose and immense love for G-d pours off the pages. Half way through the book he tells of the intentional (kavanah) worship of Hasidic Jews which prompted me to search for "Hasidic Worship" in YouTube. As divine providence would have it, I stumbled upon two versions of "Im Hashem Lo Yivneh Bayit," one of which was a rendition by Laeli.</p> 
    
                    <p>After months of their debut album playing on repeat, I wanted more, only to discover their website had expired and the domain name was for sale. So I did what any person who loves the creative work of an artist that inspires them to love G-d more—I purchaed the domain name and put together this site so that their legacy can continue to reach the hearts and minds of those who are seeking Hashem.</p>

                    <p>In addition, I've conveniently embedded their YouTube videos in order to help them rank in search and hopefully find a new, broader audience. </p>

                    <p>May you be as blessed by this album as I have been.</p>

                    <p>Shalom!</p>
                  </div>
                </div>
                </div>
                  <div className="column is-10 is-offset-1">
                    <div className="column is-10 is-offset-1">
                      <div className="iframe-container">
                        <iframe className="responsive-iframe" width="800" height="400" src="https://www.youtube.com/embed/JVtvPnjhXvw?si=EKYZuHPb946hJAhR" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                  <div className="column is-12 has-text-centered">
                    <Link className="button is-primary" to="http://www.youtube.com/@laelimusic499">
                      Visit the Laeli YouTube Channel
                    </Link>
                  </div>
                {/* <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/products">
                      See all products
                    </Link>
                  </div>
                </div> */}
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                    Latest Videos
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="button is-primary" to="/videos">
                      View all Videos
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 600, height:300, quality: 80, layout: CONSTRAINED)
              }
            }
            blurbheading
            text
          }
          heading
          description
        }
      }
    }
  }
`;
